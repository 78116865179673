import React from 'react'
import './footer.css'

// import {FaFacebookF} from 'react-icons/fa'
// import {FiInstagram} from 'react-icons/fi'
// import {SiLinkedin} from 'react-icons/si'
 
const Footer = () => {
  return (
    <footer id='footer'>
      <a href="#" className='footer__logo'>Jawad Bin Azhar</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        {/*<li><a href="#services">Services</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>

      {/* <div className="footer__socials">
        <a href="https://facebook.com"><FaFacebookF/></a>
        <a href="https://instagram.com"><FiInstagram/></a>
        <a href="https://linkedin.com"><SiLinkedin/></a>
      </div> */}

      <div className="footer__copyright">
        <small>© Jawad Bin Azhar ©</small> <br/>
        <small>&#x26a1; Powered by ReactJS &#x26a1; </small> <br/> 
       <a href="https://www.youtube.com/c/EGATORTUTORIALS" target="_blank" rel="noreferrer"><small>💕 Special Thanks to Egator 💕</small></a> 
      </div>




    </footer>
  )
}

export default Footer