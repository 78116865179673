import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_43yungo', 'template_ocy57ms', form.current, 'mtR4eYQZWJvybGkb_')
      .then((result) => {
          console.log(result.text);
          
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">

      <div className="contact__options">
        <article className='contact__option'>
          <MdOutlineEmail className='contact__option-icon'/>
          <h4>Email</h4>
          <h5>jawadazhar96@gmail.com</h5>
          <a href="mailto:jawadazhar96@gmail.com" target='_blank'>Send a Message</a>
        </article>

        <article className='contact__option'>
          <AiOutlineLinkedin className='contact__option-icon'/>
          <h4>LinkedIn</h4>
          <h5>jawadbinazhar</h5>
          <a href="https://www.linkedin.com/in/jawadbinazhar/" target='_blank'>Send a Message</a>
        </article>

        {/* <article className='contact__option'>
          <BsWhatsapp className='contact__option-icon'/>
          <h4>Whatsapp</h4>
          <h5>6703**85</h5>
          <a href="https://api.whatsapp.com/send?phone=+96567039985" target='_blank'>Send a Message</a>
        </article> */}
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='name' placeholder='Your Full Name' required />
        <input type="email" name="email" placeholder='Your Email' required />
        <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
        <button type="submit" className='btn btn-primary'>Send Message</button>
      </form>  
      </div>      
    </section>
  )
}

export default Contact