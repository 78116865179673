import React from 'react'
import './portfolio.css'
import giglogo from '../../assets/gig.jpg'
import zainlogo from '../../assets/eezee.jpg'



//array of Projects

const data = [
  {
    id: 1,
    image: zainlogo,
    title: 'Zain Kuwait',
    github: '',
    demo: 'https://www.kw.zain.com/en/',
    tech: 'Liferay'
  },
{ 
  id: 2,
  image: giglogo,
  title: 'GIG Kuwait',
  github: '',
  demo: 'https://www.gig.com.kw',
  tech: 'Liferay'
}
]

function Portfolio() {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo,tech}) => {
            return (
              
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
               <img src={image} alt={title} />
              </div>
               <h3>{title}</h3>
               <div className="portfolio__item-cta">
              <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Check out</a>
              </div>
                </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio