import React from 'react'
import  {BsLinkedin} from 'react-icons/bs'
import  {BsGithub} from 'react-icons/bs'
// import  {BsFacebook} from 'react-icons/bs'

function HeaderSocials() {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/jawadbinazhar/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
        <a href="https://github.com/jawaddpskw" target="_blank" rel="noreferrer"><BsGithub/></a>
        {/* <a href="https://facebook.com" target="_blank" rel="noreferrer"><BsFacebook/></a> */}
        
    </div>
  )
}

export default HeaderSocials