import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/header-img.png'
import HeaderSocials from './HeaderSocials'
import { TypeAnimation } from 'react-type-animation';


const Header = () => {
  return (
    <header>
       <div className="container header__container">
         <h2>السَّلَامُ عَلَيْكُمْ</h2>
         {/* <h5>Hello I am</h5> */}
         <h1>Jawad Bin Azhar</h1>
         <h5>I am a</h5>
         <TypeAnimation sequence={[
          'Software Engineer',
          1000,
          'Tech Explorer',
          2000,
          'Gamer',
          2000,
          ]} speed={40} 
          className='text-light'
          wrapper='span'
          repeat={Infinity} /> 
         {/* <h5 className='text-light'>Software Engineer</h5> */}
         <CTA/>
          <HeaderSocials />
        <div className='me'>
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>

       </div>
    </header>
  )
}

export default Header